export const TextColors = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "white",
];

export const BackgroundColors = [
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
  "transparent",
];
