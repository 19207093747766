export const languageOptions = [
  { value: "en", label: "English (United States)" },
  { value: "en-GB", label: "English (United Kingdom)" },
  { value: "en-IN", label: "English (India)" },
  { value: "en-AU", label: "English (Australia)" },
  { value: "en-CA", label: "English (Canada)" },
  { value: "en-GH", label: "English (Ghana)" },
  { value: "en-HK", label: "English (Hong Kong SAR)" },
  { value: "en-IE", label: "English (Ireland)" },
  { value: "en-KE", label: "English (Kenya)" },
  { value: "en-NG", label: "English (Nigeria)" },
  { value: "en-NZ", label: "English (New Zealand)" },
  { value: "en-PH", label: "English (Philippines)" },
  { value: "en-SG", label: "English (Singapore)" },
  { value: "en-TZ", label: "English (Tanzania)" },
  { value: "en-ZA", label: "English (South Africa)" },
  { value: "af-ZA", label: "Afrikaans (South Africa)" },
  { value: "am-ET", label: "Amharic (Ethiopia)" },
  { value: "ar-AE", label: "Arabic (United Arab Emirates)" },
  { value: "ar-BH", label: "Arabic (Bahrain)" },
  { value: "ar-DZ", label: "Arabic (Algeria)" },
  { value: "ar-EG", label: "Arabic (Egypt)" },
  { value: "ar-IL", label: "Arabic (Israel)" },
  { value: "ar-IQ", label: "Arabic (Iraq)" },
  { value: "ar-JO", label: "Arabic (Jordan)" },
  { value: "ar-KW", label: "Arabic (Kuwait)" },
  { value: "ar-LB", label: "Arabic (Lebanon)" },
  { value: "ar-LY", label: "Arabic (Libya)" },
  { value: "ar-MA", label: "Arabic (Morocco)" },
  { value: "ar-OM", label: "Arabic (Oman)" },
  { value: "ar-PS", label: "Arabic (Palestinian Authority)" },
  { value: "ar-QA", label: "Arabic (Qatar)" },
  { value: "ar-SA", label: "Arabic (Saudi Arabia)" },
  { value: "ar-SY", label: "Arabic (Syria)" },
  { value: "ar-TN", label: "Arabic (Tunisia)" },
  { value: "ar-YE", label: "Arabic (Yemen)" },
  { value: "az-AZ", label: "Azerbaijani (Latin, Azerbaijan)" },
  { value: "bg-BG", label: "Bulgarian (Bulgaria)" },
  { value: "bn-IN", label: "Bengali (India)" },
  { value: "bs-BA", label: "Bosnian (Bosnia and Herzegovina)" },
  { value: "ca-ES", label: "Catalan" },
  { value: "cs-CZ", label: "Czech (Czechia)" },
  { value: "cy-GB", label: "Welsh (United Kingdom)" },
  { value: "da-DK", label: "Danish (Denmark)" },
  { value: "de-AT", label: "German (Austria)" },
  { value: "de-CH", label: "German (Switzerland)" },
  { value: "de-DE", label: "German (Germany)" },
  { value: "el-GR", label: "Greek (Greece)" },
  { value: "es-AR", label: "Spanish (Argentina)" },
  { value: "es-BO", label: "Spanish (Bolivia)" },
  { value: "es-CL", label: "Spanish (Chile)" },
  { value: "es-CO", label: "Spanish (Colombia)" },
  { value: "es-CR", label: "Spanish (Costa Rica)" },
  { value: "es-CU", label: "Spanish (Cuba)" },
  { value: "es-DO", label: "Spanish (Dominican Republic)" },
  { value: "es-EC", label: "Spanish (Ecuador)" },
  { value: "es-ES", label: "Spanish (Spain)" },
  { value: "es-GQ", label: "Spanish (Equatorial Guinea)" },
  { value: "es-GT", label: "Spanish (Guatemala)" },
  { value: "es-HN", label: "Spanish (Honduras)" },
  { value: "es-MX", label: "Spanish (Mexico)" },
  { value: "es-NI", label: "Spanish (Nicaragua)" },
  { value: "es-PA", label: "Spanish (Panama)" },
  { value: "es-PE", label: "Spanish (Peru)" },
  { value: "es-PR", label: "Spanish (Puerto Rico)" },
  { value: "es-PY", label: "Spanish (Paraguay)" },
  { value: "es-SV", label: "Spanish (El Salvador)" },
  { value: "es-US", label: "Spanish (United States)" },
  { value: "es-UY", label: "Spanish (Uruguay)" },
  { value: "es-VE", label: "Spanish (Venezuela)" },
  { value: "et-EE", label: "Estonian (Estonia)" },
  { value: "eu-ES", label: "Basque" },
  { value: "fa-IR", label: "Persian (Iran)" },
  { value: "fi-FI", label: "Finnish (Finland)" },
  { value: "fil-PH", label: "Filipino (Philippines)" },
  { value: "fr-BE", label: "French (Belgium)" },
  { value: "fr-CA", label: "French (Canada)" },
  { value: "fr-CH", label: "French (Switzerland)" },
  { value: "fr-FR", label: "French (France)" },
  { value: "ga-IE", label: "Irish (Ireland)" },
  { value: "gl-ES", label: "Galician" },
  { value: "gu-IN", label: "Gujarati (India)" },
  { value: "he-IL", label: "Hebrew (Israel)" },
  { value: "hi", label: "Hindi (India)" },
  { value: "hr-HR", label: "Croatian (Croatia)" },
  { value: "hu-HU", label: "Hungarian (Hungary)" },
  { value: "hy-AM", label: "Armenian (Armenia)" },
  { value: "id-ID", label: "Indonesian (Indonesia)" },
  { value: "is-IS", label: "Icelandic (Iceland)" },
  { value: "it-CH", label: "Italian (Switzerland)" },
  { value: "it-IT", label: "Italian (Italy)" },
  { value: "ja-JP", label: "Japanese (Japan)" },
  { value: "jv-ID", label: "Javanese (Latin, Indonesia)" },
  { value: "ka-GE", label: "Georgian (Georgia)" },
  { value: "kk-KZ", label: "Kazakh (Kazakhstan)" },
  { value: "km-KH", label: "Khmer (Cambodia)" },
  { value: "kn-IN", label: "Kannada (India)" },
  { value: "ko-KR", label: "Korean (Korea)" },
  { value: "lo-LA", label: "Lao (Laos)" },
  { value: "lt-LT", label: "Lithuanian (Lithuania)" },
  { value: "lv-LV", label: "Latvian (Latvia)" },
  { value: "mk-MK", label: "Macedonian (North Macedonia)" },
  { value: "ml-IN", label: "Malayalam (India)" },
  { value: "mn-MN", label: "Mongolian (Mongolia)" },
  { value: "mr-IN", label: "Marathi (India)" },
  { value: "ms-MY", label: "Malay (Malaysia)" },
  { value: "mt-MT", label: "Maltese (Malta)" },
  { value: "my-MM", label: "Burmese (Myanmar)" },
  { value: "nb-NO", label: "Norwegian Bokmål (Norway)" },
  { value: "ne-NP", label: "Nepali (Nepal)" },
  { value: "nl-BE", label: "Dutch (Belgium)" },
  { value: "nl-NL", label: "Dutch (Netherlands)" },
  { value: "pa-IN", label: "Punjabi (India)" },
  { value: "pl-PL", label: "Polish (Poland)" },
  { value: "ps-AF", label: "Pashto (Afghanistan)" },
  { value: "pt-BR", label: "Portuguese (Brazil)" },
  { value: "pt-PT", label: "Portuguese (Portugal)" },
  { value: "ro-RO", label: "Romanian (Romania)" },
  { value: "ru-RU", label: "Russian (Russia)" },
  { value: "si-LK", label: "Sinhala (Sri Lanka)" },
  { value: "sk-SK", label: "Slovak (Slovakia)" },
  { value: "sl-SI", label: "Slovenian (Slovenia)" },
  { value: "so-SO", label: "Somali (Somalia)" },
  { value: "sq-AL", label: "Albanian (Albania)" },
  { value: "sr-RS", label: "Serbian (Cyrillic, Serbia)" },
  { value: "sv-SE", label: "Swedish (Sweden)" },
  { value: "sw-KE", label: "Swahili (Kenya)" },
  { value: "sw-TZ", label: "Swahili (Tanzania)" },
  { value: "ta-IN", label: "Tamil (India)" },
  { value: "te-IN", label: "Telugu (India)" },
  { value: "th-TH", label: "Thai (Thailand)" },
  { value: "tr-TR", label: "Turkish (Türkiye)" },
  { value: "uk-UA", label: "Ukrainian (Ukraine)" },
  { value: "ur-IN", label: "Urdu (India)" },
  { value: "uz-UZ", label: "Uzbek (Latin, Uzbekistan)" },
  { value: "vi-VN", label: "Vietnamese (Vietnam)" },
  { value: "wuu-CN", label: "Chinese (Wu, Simplified)" },
  { value: "yue-CN", label: "Chinese (Cantonese, Simplified)" },
  { value: "zh-CN", label: "Chinese (Mandarin, Simplified)" },
  { value: "zh-CN-handong", label: "Chinese (Jilu Mandarin, Simplified)" },
  {
    value: "zh-CN-ichuan",
    label: "Chinese (Southwestern Mandarin, Simplified)",
  },
  { value: "zh-HK", label: "Chinese (Cantonese, Traditional)" },
  { value: "zh-TW", label: "Chinese (Taiwanese Mandarin, Traditional)" },
  { value: "zu-ZA", label: "Zulu (South Africa)" },
];
